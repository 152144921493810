<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="申请人身份：">
        <el-select v-model="searchForm.identity_type" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="服务商" :value="1"></el-option>
          <el-option label="商家" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请单号：">
        <el-input size="small" v-model="searchForm.withdraw_no" placeholder="请输入申请单号"></el-input>
      </el-form-item>
      <el-form-item label="联系电话：">
        <el-input size="small" v-model="searchForm.mobile" placeholder="请输入联系电话"></el-input>
      </el-form-item>
      <el-form-item label="提现状态：">
        <el-select v-model="searchForm.status" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="待审核" :value="1"></el-option>
          <el-option label="已结算" :value="2"></el-option>
          <el-option label="已驳回" :value="-1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请时间：">
        <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="create_time" label="申请时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column prop="withdraw_no" label="申请单号" align="center"></el-table-column>
      <el-table-column prop="identity_type" label="申请人身份" align="center">
        <template v-slot="{ row }">
          {{ row.identity_type == 1 ? '服务商' : '商家' }}
        </template>
      </el-table-column>
      <el-table-column prop="identity_type" label="申请人联系电话" align="center">
        <template v-slot="{ row }">
          {{ row.identity_type == 1 ? row.f_mobile : row.s_mobile }}
        </template>
      </el-table-column>
      <el-table-column prop="withdrawal_amount" label="提现金额" align="center">
        <template v-slot="{ row }">￥{{ row.withdrawal_amount }}</template>
      </el-table-column>
      <el-table-column prop="status" label="提现状态" align="center">
        <template v-slot="{ row }">{{ row.status == 1 ? '待审核' : row.status == 2 ? '已结算' : '已驳回' }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button @click="audit(row)" type="text" v-if="row.status == 1">审核</el-button>
          <el-button @click="seeDetail(row)" type="text">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="审核" :visible.sync="showAudit" width="650px">
      <el-form label-width="100px">
        <el-form-item label="审核结果：">
          <el-radio-group v-model="auditForm.status">
            <el-radio :label="2">同意打款</el-radio>
            <el-radio :label="-1">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="开户行：">{{ currentInfo.bank_info }}</el-form-item>
        <el-form-item label="银行卡号：">{{ currentInfo.bank_card }}</el-form-item>
        <el-form-item label="持卡人：">{{ currentInfo.bank_name }}</el-form-item>
        <el-form-item label="打款金额：" v-if="auditForm.status == 2">
          <el-input v-model="auditForm.remit_amount"></el-input>
        </el-form-item>
        <el-form-item label="打款凭证：" v-if="auditForm.status == 2">
          <ReadyUploadSource
            :showStyle="{
              width: '150px',
              height: '100px',
            }"
            @getSource="val => (auditForm.voucher = val.path)"
            :path="auditForm.voucher"
            @removeThis="() => (auditForm.voucher = '')"
          ></ReadyUploadSource>
        </el-form-item>
        <el-form-item label="驳回原因：" v-if="auditForm.status == -1">
          <el-input type="textarea" :rows="5" v-model="auditForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAudit = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmAudit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="showDetail" width="650px">
      <el-form label-width="100px">
        <el-form-item label="审核状态：">{{ currentInfo.status == 1 ? '待审核' : currentInfo.status == 2 ? '已结算' : '已驳回' }}</el-form-item>
        <el-form-item label="开户行：">{{ currentInfo.bank_info }}</el-form-item>
        <el-form-item label="银行卡号：">{{ currentInfo.bank_card }}</el-form-item>
        <el-form-item label="持卡人：">{{ currentInfo.bank_name }}</el-form-item>
        <el-form-item label="打款金额" v-if="currentInfo.status == 2">{{ currentInfo.remit_amount }}</el-form-item>
        <el-form-item label="打款凭证" v-if="currentInfo.status == 2">
            <el-image style="width:160;height:90px;" :src="currentInfo.voucher"  :preview-src-list="[currentInfo.voucher]"></el-image>
        </el-form-item>
        <el-form-item label="驳回原因：" v-if="currentInfo.status == -1">{{ currentInfo.remark }}</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDetail = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmAudit">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  components: {
    Paging,
    ReadyUploadSource,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        start_time: '',
        end_time: '',
        identity_type: '',
        withdraw_no: '',
        mobile: '',
        status: '',
      },
      list: [],
      total_number: 0,
      showAudit: !1,
      currentInfo: {},
      auditForm: {
        status: 2,
        remark: '',
        remit_amount: '',
        voucher: '',
      },
      showDetail: !1,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        start_time: '',
        end_time: '',
        identity_type: '',
        withdraw_no: '',
        mobile: '',
        status: '',
      };
      this.getList();
    },
    audit(row) {
      this.currentInfo = row;
      this.showAudit = !0;
      this.auditForm = {
        id: row.id,
        status: 2,
        remark: '',
        remit_amount: '',
        voucher: '',
      };
    },
    seeDetail(row) {
      this.currentInfo = row;
      this.showDetail = !0;
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.serviceProvider.withdrawList, searchForm).then(res => {
        if (res.code == 0) {
          console.log(res);
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    comfirmAudit() {
      let auditForm = this.auditForm;
      if (auditForm.status == 2) {
        if (!auditForm.remit_amount) return this.$message.warning('请填写打款金额');
        if (!auditForm.voucher) return this.$message.warning('请添加打款凭证');
      } else {
        if (!auditForm.remark) return this.$message.warning('请填写驳回原因');
      }
      this.$axios.post(this.$api.serviceProvider.withdrawAudit, auditForm).then(res => {
        if (res.code == 0) {
          this.showAudit = !1;
          this.$message.success('处理成功');
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>